import { Link } from "gatsby"
import React from "react"

import { useMediaQuery } from "react-responsive"
import { slide as Menu } from "react-burger-menu"
import logo from "../../images/lbs.png"
import "./header.css"
import "./Global.css"

const Header = () => {
  const isDesktop = useMediaQuery({ query: "(min-width: 768px)" })
  return (
    <>
      {isDesktop ? (
        <header id="default-header">
          <Link to="/">
            <img id="logo" src={logo} alt="Life Beyond Screens Logo" />
          </Link>
          <nav>
            <ul id="links">
              <li id="link">
                {" "}
                <a href="https://www.reddit.com/r/LifeBeyondScreens/">
                  Community{" "}
                </a>
              </li>{" "}
              <li id="link">
                <Link to="/blog/">Blog</Link>
              </li>{" "}
              <li id="link">
                <a href="https://guide.lifebeyondscreens.com/">
                  Recovery Guide{" "}
                </a>
              </li>{" "}
              <li id="link">
                <a href="https://vicedrop.lifebeyondscreens.com/">ViceDrop</a>
              </li>{" "}
              <Link to="/vicedrop/">
                <li id="link-highlight">
                  {" "}
                  <div>Start Here</div>
                </li>{" "}
              </Link>
            </ul>
          </nav>
        </header>
      ) : (
        <header id="default-header">
          <Link to="/">
            <img id="logo" src={logo} alt="Life Beyond Screens Logo" />
          </Link>
          <Menu
            customCrossIcon={false}
            burgerBarClassName={"bar-white"}
            burgerButtonClassName={"button-right"}
            itemListClassName={"items-left"}
            right
          >
            <nav id="header-nav">
              <ul id="links">
                <li id="link">
                  <Link to="/">Home</Link>
                </li>
                <li id="link">
                  {" "}
                  <a href="https://www.reddit.com/r/LifeBeyondScreens/">
                    Community{" "}
                  </a>
                </li>{" "}
                <li id="link">
                  <Link to="/blog/">Blog</Link>
                </li>{" "}
                <li id="link">
                  <a href="https://guide.lifebeyondscreens.com/">
                    Recovery Guide{" "}
                  </a>
                </li>{" "}
                <li id="link">
                  <a href="https://vicedrop.lifebeyondscreens.com/">
                    ViceDrop{" "}
                  </a>
                </li>{" "}
                <Link to="/vicedrop/">
                  <li id="link-highlight">
                    {" "}
                    <div>Start Here</div>
                  </li>{" "}
                </Link>
              </ul>
            </nav>
          </Menu>
        </header>
      )}
    </>
  )
}

export default Header
