import React from "react"
import { graphql, Link } from "gatsby"
import SEO from "../components/seo"
import Header from "../components/headers/header"
import Footer from "../components/footers/footer"
import "./markdown.css"
export default function Template({ data }) {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  return (
    <>
      <Header />
      <SEO title={frontmatter.title} description={frontmatter.description} />

      <main style={{ marginTop: "4.4em" }}>
        <div id="markdown-container">
          <div
            dangerouslySetInnerHTML={{ __html: html }}
            id="markdown-content"
          />
        </div>
      </main>

      <Footer />
    </>
  )
}
export const pageQuery = graphql`
  query($path: String) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        description
        type
      }
    }
  }
`
